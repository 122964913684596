<template>
  <div class="datenschutz pt-20 lg:px-52 text-primary-soft-white">
    <h1 class="pt-10 text-xl lg:text-5xl font-bold uppercase">
      Veröffentlichungsfreigabe
    </h1>
    <ul>
      <li>
        <br />
        Die vorliegende Veröffentlichungsfreigabe („Veröffentlichungsfreigabe“)
        regelt die Bedingungen für die von den Teilnehmern (nachfolgend
        „Teilnehmer“) online generierten Beiträge (im Weiteren Verlauf auch
        Material), welche der Philip Morris GmbH, mit Sitz Am Haag 14, 82166
        Gräfelfing, Deutschland („PMG“) und den mit ihr verbundenen Unternehmen
        sowie und ihren jeweiligen Muttergesellschaften, Tochtergesellschaften,
        Lizenznehmer, Rechtsnachfolger und Zessionare („verbundene
        Unternehmen"), über https://de.iqosclub.com/togetherx/application zur
        Verfügung gestellt wurden. Im Folgenden gelten Personenbezeichnungen
        gleichermaßen für Personen jeden Geschlechts.
      </li>
    </ul>

    <h3>I. Veröffentlichungsfreigabe</h3>

    <ul>
      <li>
        1.&ensp;Mit Bestätigung dieser Veröffentlichungsfreigabe erklärt sich
        der Teilnehmer damit einverstanden, dass PMG und ihre verbundenen
        Unternehmen vollumfänglich und unbeschränkt berechtigt sind:
      </li>

      <li>
        <br />(a)&ensp;auf den Teilnehmer zu verweisen und seinen Namen zu
        verwenden,
      </li>
      <li>
        <br />(b)&ensp;seine IQOS TogetherXMusic – IQOS Unexpected Voices
        Materialien, wie Bilder, Video, seine Zitate und seine Stimme, wie sie
        im Rahmen der oben genannten Webseite zur Verfügung gestellt wurden, zu
        nutzen und
      </li>
      <li>
        <br />(c)&ensp;für jegliche von PMG entwickelten Werbemaßnahmen,
        Verkaufsförderungs- und Werbematerialen, einschließlich TV-Spots,
        Radio-Spots, Emails/Newsletter, Plakatwerbung, Poster, postalische
        Sendung, , Broschüren, Online-Anzeigen, virale Spots und
        Merchandising-Produkte, zur Nutzung in allen Märkten weltweit, in
        jeglichen Medien, insbesondere Fernsehen, Radio, Internet einschließlich
        Social-Media-Plattformen und Mobilgeräten oder an sonstigen Orten oder
        Events, insbesondere für Werbung in jeglicher Form für PMG und ihre
        verbundenen Unternehmen zu nutzen.
      </li>
      <li>
        <br />2.&ensp;Der Teilnehmer erkennt an, dass PMG und die mit PMG
        verbundenen Unternehmen das exklusive Recht zur Nutzung und Verwertung
        des Materials in sämtlichen Märkten weltweit, auf jegliche Art sowie
        über sämtliche weltweiten Medien, auf unbestimmte Zeit eingeräumt wird.
        Diese Rechteeinräumung umfasst insbesondere das Recht das Material ganz
        oder teilweise zu veröffentlichen, zu senden, öffentlich zugänglich zu
        machen, zu vervielfältigen, auszustellen, zu verbreiten sowie – unter
        gebührender Berücksichtigung und unter dem Vorbehalt der Wahrung der
        geistigen Eigenart des Materials – zu bearbeiten, insbesondere zu
        kürzen, umzugestalten und mit anderen audiovisuellen Produktionen oder
        sonstigen Werken vollständig oder teilweise zu verbinden und in allen
        öffentlichen und nicht-öffentlichen audiovisuellen Medien zu
        kommerziellen und nichtkommerziellen Zwecken zu nutzen, und das Material
        in Datenbanken zu verwenden und zu archivieren. Der Teilnehmer bestätigt
        ebenso, dass PMG und/oder eines der mit PMG verbundenen Unternehmen
        sämtliche Urheber- und Leistungsschutzrechte und Rechte am geistigen
        Eigentum am Material zur weltweiten, räumlich, zeitlich und inhaltlich
        unbeschränkten Auswertung in allen derzeit bekannten und noch bekannt
        werdenden Medien exklusiv übertragen werden. Dem Teilnehmer stehen
        keinerlei Rechte, Eigentumsansprüche oder Anteile an oder in Bezug auf
        das Material oder Teile hiervon, oder in Bezug auf oder in Verbindung
        mit sämtlichen aus dem Material erzielten Erlösen zu.
      </li>
      <li>
        <br />
        PMG ist berechtigt, die PMG hiermit eingeräumten Rechte und Befugnisse
        ganz oder teilweise auf die mit ihr verbundenen Unternehmen oder Dritte
        zu übertragen oder Dritten Unterlizenzen einzuräumen. Für eine solche
        Übertragung entsteht – selbst im Falle der Übertragung des gesamten
        Materials oder der mehrfachen Auswertung des Materials – in keinem Fall
        ein Vergütungsanspruch des Teilnehmers.
      </li>

      <li>
        <br />3.&ensp;Der Teilnehmer bestätigt, dass die im Material geäußerten
        Meinungen seine eigene Meinung wiedergeben und er nicht auf irgendeine
        Weise von irgendeiner Seite beeinflusst worden ist. Der Teilnehmer
        versichert, dass er als Rechteinhaber an dem eingereichten Material
        berechtigt ist, die Rechte gemäß § 1 an PMG einzuräumen, und dass der
        Nutzung und Weiterübertragung dieser Rechte durch PMG keine Rechte
        Dritter entgegenstehen. Er versichert insbesondere, dass der Inhalt und
        alle Teile des Materials nicht widerrechtlich oder geschützten Werken
        Dritter entnommen sind und keine Rechte Dritter verletzen. Der
        Teilnehmer versichert zudem, dass durch die Verwendung des Materials im
        Rahmen dieses Vertrags keine Persönlichkeitsrechte Dritter verletzt
        werden, insbesondere, dass abgebildete oder gefilmte Personen mit der
        (unentgeltlichen) vertragsgegenständlichen Nutzung einverstanden sind.
      </li>
      <li>
        <br />4.&ensp;Die PMG und die ihr verbundenen Unternehmen behalten sich
        vor, das Material vor einer Veröffentlichung zu prüfen. Nach
        erfolgreicher Prüfung und Freigabe erscheint der Beitrag des Teilnehmers
        auf den IQOS TogetherXMusic Seiten https://de.iqosclub.com/togetherx.
        Der Teilnehmer erhält nach Prüfung eine separate Benachrichtigung.
      </li>

      <li>
        <br />5.&ensp;Die Haftung von PMG für Schäden, die von PMG oder einem
        ihrer Erfüllungsgehilfen oder gesetzlichem Vertreter vorsätzlich oder
        grob fahrlässig verursacht werden, ist unbeschränkt.
      </li>
      <li>
        <br />Die Haftung von PMG für Schäden, die auf ein schwerwiegendes
        Organisationsverschuldens von PMG zurückzuführen sind ist ebenfalls
        unbeschränkt.
      </li>
      <li>
        <br />Unbeschadet der zuvor genannten Bestimmungen haftet PMG für die
        Verletzung von wesentlichen Vertragspflichten (Kardinalpflichten) im
        Rahmen bzw. Umfang des vertragstypisch vorhersehbaren Schadens.
        Kardinalpflichten sind wesentliche Vertragspflichten, die die
        ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und
        auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf.
      </li>
      <li>
        <br />Jegliche weitere Haftung von PMG für Schäden, die vorstehend nicht
        aufgeführt sind, ist ausgeschlossen, insbesondere die Haftung für
        Schäden ohne Verschulden. Diese Haftungsbeschränkung gilt nicht für
        Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit.
      </li>
    </ul>

    <h3>II. Allgemeines</h3>
    <ul>
      <li>
        1.&ensp;Der Teilnehmer bestätigt, dass er die vorliegende
        Veröffentlichungsfreigabe sorgfältig gelesen hat, deren Bedingungen in
        vollem Umfang versteht und ihm bewusst ist, dass er durch seine erklärte
        Zustimmung Rechte aufgegeben hat. Der Teilnehmer hat dies aus freien
        Stücken getan, ohne hierzu verleitet oder genötigt oder gezwungen worden
        zu sein.
      </li>

      <li>
        <br />2.&ensp;Um sicherzustellen, dass der Beitrag den
        Veröffentlichungskriterien gem. Ziff. I Nr. 4 entspricht, hat der
        Teilnehmer folgendes zu beachten:
      </li>
      <li>
        <br />
        - &ensp; Der angegebene Name muss mit dem des Ausweisdokuments des
        Teilnehmers übereinstimmen.
      </li>
      <li>
        <br />
        - &ensp; Es dürfen keine Minderjährigen und Jugendlichen auf den
        Materialien zu sehen sein. Auch berühmte Persönlichkeiten oder Cartoons,
        die eine minderjährige/jugendliche Zielgruppe ansprechen, dürfen nicht
        gezeigt werden.
      </li>
      <li>
        <br />
        - &ensp; Es dürfen keine Tiere (auch Stofftiere o.ä.) abgebildet sein.
      </li>
      <li>
        <br />
        - &ensp; Unabhängig ihres Bekanntheitsgrades dürfen Namen, Fotos,
        Stimmen, Melodien, Lieder, Texte oder andere Merkmale von lebenden oder
        toten Persönlichkeiten des öffentlichen Lebens nicht gezeigt oder
        erwähnt werden.
      </li>
      <li>
        <br />
        - &ensp; Das Material darf nicht durch Gesichtsfilter oder anderen
        Filtern unkenntlich und verzerrt sein.
      </li>
      <li>
        <br />
        - &ensp; Das Material darf sich nicht auf das Aufhören von Rauchen
        beziehen, oder IQOS als Tool zur Rauchentwöhnung positionieren.
      </li>
      <li>
        <br />
        - &ensp; Im Material darf nicht auf gesundheitliche Aspekte verwiesen
        werden.
      </li>
      <li>
        <br />
        - &ensp; Die Bilder dürfen keine ausländischen HEETS oder TEREA
        Packungen zeigen.
      </li>
      <li>
        <br />- &ensp; Es dürfen kein Essen oder Getränke abgebildet sein.
      </li>
      <li>
        <br />
        - &ensp; Das Material darf keine vertraulichen oder nicht-öffentlichen
        Dritter enthalten.
      </li>
      <li>
        <br />
        - &ensp; Es dürfen keine unangemessenen Kommentare oder Rückschlüsse
        gegen unsere Wettbewerber enthalten sein (z.B. abwertende Kommentare
        oder Aussagen gegenüber Produkten und Unternehmen).
      </li>
      <li>
        <br />
        - &ensp; Das Material darf keine unangebrachten, unmoralischen oder
        politisch inkorrekten Aussagen beinhalten. Der Content darf keine
        diskriminierenden Kommentare bezüglich Herkunft, ethnischer
        Zugehörigkeit, Religion, Geschlecht, Behinderung, sexueller Orientierung
        oder politischen Überzeugungen enthalten.
      </li>
      <li>
        <br />
        - &ensp; Zitate, Bilder, Lieder oder geistiges Eigentum Dritter dürfen
        nicht verwendet werden. Der Teilnehmer muss Urheber und Besitzer des
        Materials sein.
      </li>
      <li>
        <br />
        - &ensp; Es dürfen keine Texte, Bilder oder ähnliches veröffentlich
        werden, die als obszön, unanständig, sexuell anzüglich, primitiv,
        geschmacklos, provozierend oder pornografisch angesehen werden könnten.
      </li>

      <li>
        <br />3.&ensp;Der Teilnehmer stimmt zu, dass das Material bei PMG
        gespeichert und aufbewahrt wird.
      </li>
      <li>
        <br />4.&ensp;Der Teilnehmer erkennt an, dass für den Fall, dass eine
        Bestimmung dieser Veröffentlichungsfreigabe (oder Teile einer solchen
        Bestimmung) von einem zuständigen Gericht oder einer zuständigen Behörde
        für unwirksam, nicht durchsetzbar oder rechtswidrig gehalten wird, die
        übrigen Bestimmungen (oder Teile von Bestimmungen) weiter wirksam
        bleiben. Der Teilnehmer erklärt sich hiermit weiterhin damit
        einverstanden, dass für den Fall, dass eine unwirksame, nicht
        durchsetzbare oder rechtswidrige Bestimmung rechtswirksam, durchsetzbar
        oder rechtmäßig wäre, wenn ein Teil der Bestimmung gestrichen würde, die
        Bestimmung mit denjenigen Änderungen, die erforderlich sind, um die
        wirtschaftlichen Absichten der Parteien umzusetzen, weitergilt.
      </li>
      <li>
        <br />5.&ensp;Das Recht der Bundesrepublik Deutschland ist anwendbar.
      </li>
    </ul>

    <br />
    <br />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.datenschutz {
  @apply break-words;
  background-color: #1e1b23;
}
h1,
h2,
h3,
h4 {
  @apply font-bold pb-4 pt-8 px-2 md:px-5;
}
ol {
  @apply list-none pl-4 px-2 md:px-5;
}
ul {
  @apply list-none pl-4 px-2 md:px-5;
}
li {
  @apply mb-1 px-2 md:px-5;
}
p {
  @apply mb-1 last:mb-0 pb-1 px-2 md:px-5;
}
</style>
