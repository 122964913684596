import { ResponsiveImageSet } from '@/types';

export interface TeamPickCard {
  name: string;
  genre?: string;
  artistTeamName?: string;
  videoUrl?: string;
  profileImageSet?: ResponsiveImageSet;
  pickedArtistTeamImageSet?: ResponsiveImageSet;
  finalPickedArtistTeamImageSet?: ResponsiveImageSet;
}

export const teamPickCards: TeamPickCard[] = [
  {
    name: 'Blümchen',
    genre: 'EDM',
    artistTeamName: 'bluemchen',
    profileImageSet: {
      alt: 'Blümchen',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/profiles/blümchen/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/profiles/blümchen/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/profiles/blümchen/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/profiles/blümchen/picture-mobile.png'),
        x2: require('@/assets/together-x/teamPickCards/profiles/blümchen/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/profiles/blümchen/picture-mobile@3x.png'),
      },
    },
    pickedArtistTeamImageSet: {
      alt: 'Blümchen',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/picture-mobile.png'),
        x2: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/picture-mobile@3x.png'),
      },
    },
    finalPickedArtistTeamImageSet: {
      alt: 'Blümchen',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/blümchen/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/blümchen/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/blümchen/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/blümchen/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/blümchen/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/blümchen/picture@3x.png'),
      },
    },
    videoUrl: require('@/assets/together-x/teamPickCards/pickedArtistTeam/blümchen/IQOS_TogetherXMusic_Bluemchen_CTA06_MASTER.mp4'),
  },
  {
    name: 'Kool Savas',
    genre: 'HIPHOP',
    artistTeamName: 'kool_savas',
    profileImageSet: {
      alt: 'Kool Savas',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/profiles/koolsavas/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/profiles/koolsavas/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/profiles/koolsavas/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/profiles/koolsavas/picture-mobile.png'),
        x2: require('@/assets/together-x/teamPickCards/profiles/koolsavas/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/profiles/koolsavas/picture-mobile@3x.png'),
      },
    },
    pickedArtistTeamImageSet: {
      alt: 'Kool Savas',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/picture-mobile.png'),
        x2: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/picture-mobile@3x.png'),
      },
    },
    finalPickedArtistTeamImageSet: {
      alt: 'Kool Savas',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/koolsavas/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/koolsavas/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/koolsavas/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/koolsavas/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/koolsavas/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/koolsavas/picture@3x.png'),
      },
    },
    videoUrl: require('@/assets/together-x/teamPickCards/pickedArtistTeam/koolsavas/IQOS_TogetherXMusic_KoolSavas_CTA06_MASTER.mp4'),
  },
  {
    name: 'Michael Schulte',
    genre: 'POP',
    artistTeamName: 'michael_schulte',
    profileImageSet: {
      alt: 'Michael Schulte',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/profiles/michaelschulte/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/profiles/michaelschulte/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/profiles/michaelschulte/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/profiles/michaelschulte/picture-mobile.png'),
        x2: require('@/assets/together-x/teamPickCards/profiles/michaelschulte/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/profiles/michaelschulte/picture-mobile@3x.png'),
      },
    },
    pickedArtistTeamImageSet: {
      alt: 'Michael Schulte',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/picture-mobile.png'),
        x2: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/picture-mobile@3x.png'),
      },
    },
    finalPickedArtistTeamImageSet: {
      alt: 'Michael Schulte',
      desktop: {
        x1: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/michaelschulte/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/michaelschulte/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/michaelschulte/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/michaelschulte/picture.png'),
        x2: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/michaelschulte/picture@2x.png'),
        x3: require('@/assets/together-x/teamPickCards/finalPickedArtistTeam/michaelschulte/picture@3x.png'),
      },
    },
    videoUrl: require('@/assets/together-x/teamPickCards/pickedArtistTeam/michaelschulte/IQOS_TogetherXMusic_MichaelSchulte_CTA06_MASTER.mp4'),
  },
];
