interface TeamCard {
  name: string;
  genre: string;
  normalisedName: string;
  pickArtistTeamVideoUrl: string;
  pickArtistTeamVideoPoster: string;
  pickArtistTeamThankYouVideoUrl: string;
  pickArtistTeamThankYouPoster: string;
}

const teamCards: TeamCard[] = [
  {
    name: 'Blümchen',
    normalisedName: 'bluemchen',
    genre: 'EDM',
    pickArtistTeamVideoUrl:
      'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_Bluemchen_CTA03_MASTER_1.mp4',
    pickArtistTeamVideoPoster: require('@/assets/together-x/teamPickCards/application/pickArtistTeam/bluemchen/poster-mobile@3x.jpg'),
    pickArtistTeamThankYouVideoUrl:
      'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_Bluemchen_CTA07_MASTER.mp4',
    pickArtistTeamThankYouPoster: require('@/assets/together-x/teamPickCards/application/pickedArtistTeamThankYou/bluemchen/poster-mobile@3x.jpg'),
  },
  {
    name: 'Kool Savas',
    normalisedName: 'kool_savas',
    genre: 'HIPHOP',
    pickArtistTeamVideoUrl:
      'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_KoolSavas_CTA03_MASTER_Comp.mp4',
    pickArtistTeamVideoPoster: require('@/assets/together-x/teamPickCards/application/pickArtistTeam/kool-savas/poster-mobile@3x.jpg'),
    pickArtistTeamThankYouVideoUrl:
      'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_KoolSavas_CTA07_MASTER.mp4',
    pickArtistTeamThankYouPoster: require('@/assets/together-x/teamPickCards/application/pickedArtistTeamThankYou/kool-savas/poster-mobile@3x.jpg'),
  },
  {
    name: 'Michael Schulte',
    normalisedName: 'michael_schulte',
    genre: 'POP',
    pickArtistTeamVideoUrl:
      'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_MichaelSchulte_CTA03_MASTER_Comp.mp4',
    pickArtistTeamVideoPoster: require('@/assets/together-x/teamPickCards/application/pickArtistTeam/michael-schulte/poster-mobile@3x.jpg'),
    pickArtistTeamThankYouVideoUrl:
      'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/CMS_TogetherXMusic/IQOS_TogetherXMusic_MichaelSchulte_CTA07_MASTER.mp4',
    pickArtistTeamThankYouPoster: require('@/assets/together-x/teamPickCards/application/pickedArtistTeamThankYou/michael-schulte/poster-mobile@3x.jpg'),
  },
];

export default teamCards;
