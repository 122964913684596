import { computed, ComputedRef, watch } from 'vue';
import useSWRV from 'swrv';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { useStore } from 'vuex';

import { useAuth } from '@/use/useAuth';

import { getVoting, postVoting } from '../api/api';

import type { GetVotingDTO, PostVotingDTO, VotingData } from '../types';

export const useVoting = (): {
  votingData: ComputedRef<VotingData>;
  save: (data: PostVotingDTO) => Promise<unknown>;
  mutateContent: () => void;
  isPending: ComputedRef<boolean>;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);
  const refetch = () => mutate();

  const { headers } = useAuth();
  const {
    data: votingData,
    mutate,
    error,
  } = useSWRV<GetVotingDTO>(
    'get-together-x-voting',
    () => getVoting(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  const normalisedVotingData = computed<VotingData>(() => {
    const mapper = (_, key) => camelCase(key);
    const myVotes = (votingData.value?.my_votes || []).map((voteItem) =>
      mapKeys(voteItem, mapper)
    );
    const currentDateVotes = (votingData.value?.todays_votes || []).map(
      (voteItem) => mapKeys(voteItem, mapper)
    );
    return {
      ...mapKeys(votingData.value || {}, mapper),
      myVotes,
      currentDateVotes,
    } as unknown as VotingData;
  });

  const isPending = computed(() => !votingData.value);

  const saveVotes = (postData: PostVotingDTO) => {
    return new Promise((resolve, reject) =>
      postVoting(postData)(headers.value)
        .then(() =>
          setTimeout(() => {
            refetch().then(resolve);
          }, 3000)
        ) // Revalidate after saving
        .catch((error) => {
          handleError(error);
          reject();
        })
    );
  };
  return {
    votingData: normalisedVotingData,
    save: saveVotes,
    mutateContent: refetch,
    isPending,
  };
};
