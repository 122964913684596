import { computed, ComputedRef } from 'vue';
import useSWRV from 'swrv';

import { useAuth } from '@/use/useAuth';

import { getInfos } from '../api/api';
import type { Infos, InfosDTO } from '../types';

const normaliseInfos = (item: InfosDTO): Infos => ({
  currentApplications: item?.current_applications,
  pointsForApplication: item?.points_for_application,
  pointsForTeamSupport: item?.points_for_team_support,
  usersSupportTeam: item?.users_support_team,
  teamSupportCount: item?.team_support_count,
});

export const useInfos = (): {
  infos: ComputedRef<Infos | undefined>;
  mutateContent: () => void;
} => {
  const { headers } = useAuth();
  const { data: infos, mutate } = useSWRV('infos', () =>
    getInfos(headers.value)
  );

  const normalisedInfos = computed(() => {
    return infos.value && normaliseInfos(infos.value);
  });

  return {
    infos: normalisedInfos,
    mutateContent: mutate,
  };
};
