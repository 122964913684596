import { useStore } from 'vuex';
import { useAuth } from '@/use/useAuth';

import { setSupportArtistTeam } from '../api/api';

export const useSupportArtistTeam = (): {
  saveSupportArtistTeam: (artistTeam: string) => Promise<unknown>;
} => {
  const store = useStore();

  const handleError = (err) => {
    store.commit('PUSH_ERROR', err);
    store.dispatch('togetherXModule/setIsSupportArtistTeamPickError', true);
  };

  const { headers } = useAuth();

  const saveSupportArtistTeam = (artistTeam: string) => {
    return setSupportArtistTeam(artistTeam)(headers.value).catch(handleError);
  };

  return {
    saveSupportArtistTeam,
  };
};
