import { Card } from '../types';

const coachCards: Card[] = [
  {
    header: '',
    description:
      'Ich liebe es, Menschen durch meinen Gesang und meine Performance Energie in den Körper und ein Lächeln ins Gesicht zu zaubern.',
    date: '',
    category: 'Paula',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/coachCards/card1/picture.png'),
        x2: require('@/assets/together-x/coachCards/card1/picture@2x.png'),
        x3: require('@/assets/together-x/coachCards/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/coachCards/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/coachCards/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/coachCards/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: '',
    description:
      'Bei allem was ich mache geht\'s mir darum gutes Entertainment mit echter Lebensfreude und Authentizität zu kombinieren.',
    date: '',
    category: 'Afra',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/coachCards/card2/picture-desktop.jpg'),
        x2: require('@/assets/together-x/coachCards/card2/picture-desktop@2x.jpg'),
        x3: require('@/assets/together-x/coachCards/card2/picture-desktop@3x.jpg'),
      },
      mobile: {
        x1: require('@/assets/together-x/coachCards/card2/picture-mobile.jpg'),
        x2: require('@/assets/together-x/coachCards/card2/picture-mobile@2x.jpg'),
        x3: require('@/assets/together-x/coachCards/card2/picture-mobile@3x.jpg'),
      },
    },
  },
  {
    header: '',
    description:
      'Bei allem was ich mache geht\'s mir darum gutes Entertainment mit echter Lebensfreude und Authentizität zu kombinieren.',
    date: '',
    category: 'Afra',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/coachCards/card2/picture-desktop.jpg'),
        x2: require('@/assets/together-x/coachCards/card2/picture-desktop@2x.jpg'),
        x3: require('@/assets/together-x/coachCards/card2/picture-desktop@3x.jpg'),
      },
      mobile: {
        x1: require('@/assets/together-x/coachCards/card2/picture-mobile.jpg'),
        x2: require('@/assets/together-x/coachCards/card2/picture-mobile@2x.jpg'),
        x3: require('@/assets/together-x/coachCards/card2/picture-mobile@3x.jpg'),
      },
    },
  },
];

export default coachCards;
