import { computed, watch, type ComputedRef } from 'vue';
import useSWRV from 'swrv';
import type {
  DocumentationCarouselBubbleDTO,
  DocumentationCarouselCategory,
  DocumentationCarouselItemCocreationDTO,
  DocumentationCarouselItemStoryDTO,
  GetDocumentationCarouselDTO,
} from '../types';
import { useStore } from 'vuex';
import { useAuth } from '@/use/useAuth';
import { normaliseCmsImage } from '@/components/ResponsiveImage/utils';

import { getDocumentationCarousel, postVideoFragment } from '../api/api';

const normaliseCommonFields = (value: DocumentationCarouselBubbleDTO) => ({
  title: value.title,
  image: normaliseCmsImage({ alt: value.title, image: value.image }),
  hasNewContent: value.has_new_content,
  disabled: value.disabled,
});
const normaliseStory = (
  item: DocumentationCarouselItemStoryDTO
): DocumentationCarouselCategory => ({
  ...normaliseCommonFields(item.value),
  id: item.id,
  type: 'story',
  items: item.value.items.map((videoItem) => ({
    id: videoItem.id,
    title: videoItem.value.title,
    videoUrl: videoItem.value.video_url,
    poster: videoItem.value?.poster?.url,
    ctaUrl: videoItem.value.cta_url,
    pointsAmount: videoItem.value.iqos_points,
    isStatusPoints: videoItem.value.status_points_only,
    isIqosPoints: videoItem.value.iqos_points_only,
  })),
});
const normaliseCocreation = (
  item: DocumentationCarouselItemCocreationDTO
): DocumentationCarouselCategory => ({
  ...normaliseCommonFields(item.value),
  id: item.id,
  type: 'co_creation',
  thankYouTitle: item.value.thank_you_title,
  thankYouText: item.value.thank_you_text,
  items: item.value.items.map((cocreationItem) => ({
    id: cocreationItem.id,
    artistTeam: cocreationItem.value.artist_team,
    title: cocreationItem.value.title,
    subTitle: cocreationItem.value.sub_title,
    pointsAmount: cocreationItem.value.iqos_points,
    isStatusPoints: cocreationItem.value.status_points_only,
    isIqosPoints: cocreationItem.value.iqos_points_only,
    selectionLeft: {
      id: cocreationItem.value.selection_left.id,
      name: cocreationItem.value.selection_left.name,
      image: normaliseCmsImage({
        alt: cocreationItem.value.selection_left.name,
        image: cocreationItem.value.selection_left.image,
      }),
    },
    selectionRight: {
      id: cocreationItem.value.selection_right.id,
      name: cocreationItem.value.selection_right.name,
      image: normaliseCmsImage({
        alt: cocreationItem.value.selection_right.name,
        image: cocreationItem.value.selection_right.image,
      }),
    },
  })),
});

const normalisationTypeMap = {
  story: normaliseStory,
  co_creation: normaliseCocreation,
};

export const useDocumentationCarousel = (
  params = { useCacheOnly: false }
): {
  categories: ComputedRef<DocumentationCarouselCategory[]>;
  refetch: () => void;
  watchVideoFragment: (config: {
    videoId: string;
    categoryId: string;
    percent: number;
  }) => Promise<{
    message: string;
    given_points: number;
    only_statuspoints: boolean;
  }>;
} => {
  const store = useStore();
  const handleError = (err) => {
    // Avoid displaying that error and rely on cache instead.
    if(err?.message !== '1 per 3 second') {
      store.commit('PUSH_ERROR', err)
    }
  };

  const { headers } = useAuth();
  const {
    data: categoriesList,
    error,
    mutate,
  } = useSWRV<GetDocumentationCarouselDTO>(
    'get-together-x-documentation-categories',
    params.useCacheOnly ? null : () => getDocumentationCarousel(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  const normalisedCarouselCategories = computed<
    DocumentationCarouselCategory[] | undefined
  >(() =>
    categoriesList.value?.map((item) => {
      const normalise: (
        item:
          | DocumentationCarouselItemStoryDTO
          | DocumentationCarouselItemCocreationDTO
      ) => DocumentationCarouselCategory = normalisationTypeMap[item.type];
      return normalise(item);
    })
  );

  const watchVideoFragment = (config: {
    videoId: string;
    categoryId: string;
    percent: number;
  }) => postVideoFragment(config)(headers.value);
  // .catch(handleError);

  return {
    categories: normalisedCarouselCategories,
    watchVideoFragment,
    refetch: mutate,
  };
};
