import { computed, watch, type ComputedRef } from 'vue';
import useSWRV from 'swrv';
import type { ApplicantCard, ArtistTeam, GetApplicantListDTO } from '../types';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { useStore } from 'vuex';
import { useAuth } from '@/use/useAuth';

import { getArtistSelectedApplicants } from '../api/api';

export const useArtistSelectedApplicants = ({
  artistName,
}: {
  artistName: ArtistTeam;
}): {
  artistSelectedApplicantsList: ComputedRef<ApplicantCard[]>;
  isPending: ComputedRef<boolean>;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);

  const { headers } = useAuth();
  const { data: applicantsList, error } = useSWRV<GetApplicantListDTO>(
    `get-together-x-${artistName}-selected-applicants-list`,
    () => getArtistSelectedApplicants(artistName)(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  const normalisedArtistSelectedApplicantsList = computed<ApplicantCard[]>(
    () => {
      const mapper = (__, key) => camelCase(key);
      const result = (applicantsList.value?.results || []).map((value) => {
        return mapKeys(
          {
            ...value,
            photo:
              value?.video_url?.replace('_vid.mp4', '_img.jpg') ||
              `https://api.dicebear.com/8.x/initials/svg?seed=${value.name}`,
            video: value.video_url,
          },
          mapper
        );
      });

      return result as unknown as ApplicantCard[];
    }
  );

  const isPending = computed(() => !applicantsList.value);

  return {
    artistSelectedApplicantsList: normalisedArtistSelectedApplicantsList,
    isPending,
  };
};
