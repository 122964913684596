import { computed, watch, type ComputedRef } from 'vue';
import useSWRV from 'swrv';
import type {
  DocumentationArchiveCardStoryDTO,
  DocumentationCarouselItemStoryDTO,
  ArchiveCardData,
} from '../types';
import { useStore } from 'vuex';
import { useAuth } from '@/use/useAuth';

import { getArchiveGrid } from '../api/api';

const normaliseCard = (
  item: DocumentationArchiveCardStoryDTO,
  category: string
): ArchiveCardData => ({
  id: item.id,
  category,
  title: item.value.title,
  videoUrl: item.value.video_url,
  poster: item.value?.poster?.url,
  ctaUrl: item.value.cta_url,
  pointsAmount: item.value.iqos_points,
  isStatusPoints: item.value.status_points_only,
  isIqosPoints: item.value.iqos_points_only,
});

function extractAllCards(archiveCategories) {
  const items = [];
  for (const object of archiveCategories) {
    if (object.value && object.value.items) {
      const parentCategory = object.value.title;
      items.push(
        ...object.value.items.map((item) => normaliseCard(item, parentCategory))
      );
    }
  }
  return items;
}

export const useArchiveGrid = (
  artistName: string
): {
  cards: ComputedRef<ArchiveCardData[]>;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);

  const { headers } = useAuth();
  const { data: archiveCategories, error } = useSWRV<
    DocumentationCarouselItemStoryDTO[]
  >(
    `get-together-x-documentation-archive-grid-${artistName}`,
    () => getArchiveGrid(artistName)(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  const normalisedCards = computed<ArchiveCardData[] | undefined>(() =>
    extractAllCards(archiveCategories.value)
  );

  return {
    cards: normalisedCards,
  };
};
