import { Ref, ref } from 'vue';

export const useProgress = (): {
  growLogarithmically: () => void;
  clearProgressInterval: () => void;
  progressPercentage: Ref<number>;
} => {
  let intervalId;
  const progressPercentage = ref(0);

  const growLogarithmically = (): void => {
    let growthRate = 12;
    progressPercentage.value = 1;

    intervalId = setInterval(() => {
      progressPercentage.value += growthRate;

      if (progressPercentage.value >= 98) {
        progressPercentage.value = 98;
        clearInterval(intervalId);
      }

      if (growthRate > 1) {
        growthRate -= 1;
      }
    }, 1000);
  };

  const clearProgressInterval = (): void => {
    clearInterval(intervalId);
  };

  return {
    growLogarithmically,
    clearProgressInterval,
    progressPercentage,
  };
};
