import { Card } from '../types';

const discoveryCards: Card[] = [
  {
    header: 'IQOS Cross-Body Bag',
    description: '',
    date: '01/03',
    link: '/rewardshop/product/66a789c15753c87bb0a8543e/5cc0a39c-4da5-11ef-ac61-0242ac1d0005',
    category: 'Tasche',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/discoveryCards/card5/rectangle.png'),
        x2: require('@/assets/together-x/discoveryCards/card5/rectangle@2x.png'),
        x3: require('@/assets/together-x/discoveryCards/card5/rectangle@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/discoveryCards/card5/picture-mobile.png'),
        x2: require('@/assets/together-x/discoveryCards/card5/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/discoveryCards/card5/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'JBL Go Essential bluetooth',
    description: '',
    date: '02/03',
    link: '/rewardshop/product/662a2a2981335a7c333e0e5d/e5ffefba-02ea-11ef-bece-0242ac1c0005',
    category: 'Lautsprecher',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/discoveryCards/card1/rectangle.jpg'),
        x2: require('@/assets/together-x/discoveryCards/card1/rectangle@2x.jpg'),
        x3: require('@/assets/together-x/discoveryCards/card1/rectangle@3x.jpg'),
      },
      mobile: {
        x1: require('@/assets/together-x/discoveryCards/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/discoveryCards/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/discoveryCards/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Sony True Wireless earbuds',
    description: '',
    date: '03/03',
    link: '/rewardshop/product/662a2b0f81335a7c333e0ea4/6f3020f2-02eb-11ef-bece-0242ac1c0005',
    category: 'Kopfhörer',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/discoveryCards/card2/rectangle.jpg'),
        x2: require('@/assets/together-x/discoveryCards/card2/rectangle@2x.jpg'),
        x3: require('@/assets/together-x/discoveryCards/card2/rectangle@3x.jpg'),
      },
      mobile: {
        x1: require('@/assets/together-x/discoveryCards/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/discoveryCards/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/discoveryCards/card2/picture-mobile@3x.png'),
      },
    },
  },
];

export default discoveryCards;
