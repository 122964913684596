import { ResponsiveImageSet } from '@/types';

type TeaserCard = {
  header: string;
  description: string;
  description2: string;
  details: string;
  imageSet: ResponsiveImageSet;
};

const teaserCards: TeaserCard[] = [
  {
    header: 'NEWCOMER <br>CONCERTS',
    description: 'Erlebe Deine neuen Lieblingsmusiker live mit IQOS.',
    description2:
      'Wenn Du Deinen Lieblingssong vor 5 Minuten noch nicht kanntest.',
    details:
      'Wir geben talentierten Newcomern eine Bühne und bringen sie direkt in Deine Playlists. Dran bleiben lohnt sich!',
    imageSet: {
      alt: 'NEWCOMER CONCERTS',
      desktop: {
        x1: require('@/assets/together-x/teaserCards/card1/picture.png'),
        x2: require('@/assets/together-x/teaserCards/card1/picture@2x.png'),
        x3: require('@/assets/together-x/teaserCards/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teaserCards/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/teaserCards/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teaserCards/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'KARAOKE',
    description: 'Erobere die Bühne bei unseren Community Karaoke Events.',
    description2: 'WENN DEINE FREUNDE DEIN VERSTECKTES TALENT ENTDECKEN.',
    details:
      'Zusammen singen macht glücklich: In den kommenden Monaten laden wir Dich und Deine Freunde zu unseren Community Karaoke Events ein.',
    imageSet: {
      alt: 'KARAOKE',
      desktop: {
        x1: require('@/assets/together-x/teaserCards/card2/picture.png'),
        x2: require('@/assets/together-x/teaserCards/card2/picture@2x.png'),
        x3: require('@/assets/together-x/teaserCards/card2/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teaserCards/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/teaserCards/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teaserCards/card2/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'LOOP <br class="inline md:hidden">SESSIONS',
    description: 'Erschaffe in einer Loop Session gemeinsam einen neuen Song.',
    description2:
      'Wenn ein Song geschrieben wird, während er interpretiert wird.',
    details:
      'Bringe Deine Texte und Beats ein und erlebe, wie in einer Loop Session zusammen mit Dir ein neuer Song entsteht. Wir halten Dich auf dem Laufenden.',
    imageSet: {
      alt: 'LOOP SESSIONS',
      desktop: {
        x1: require('@/assets/together-x/teaserCards/card3/picture.png'),
        x2: require('@/assets/together-x/teaserCards/card3/picture@2x.png'),
        x3: require('@/assets/together-x/teaserCards/card3/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/teaserCards/card3/picture-mobile.png'),
        x2: require('@/assets/together-x/teaserCards/card3/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/teaserCards/card3/picture-mobile@3x.png'),
      },
    },
  },
];

export default teaserCards;
