import type { GetTokyoFeedDTO, TokyoFeed } from '../types';
import { computed, watch, type ComputedRef, ref } from 'vue';
import useSWRV from 'swrv';
import { useStore } from 'vuex';
import { useAuth } from '@/use/useAuth';

import {
  getTokyoFeed,
  getTokyoPhotoUrl,
  getTokyoPhotoUrlForFeed,
  getTokyoVideoUrlForFeed,
} from '../api/api';
import type { UploadUrlDTO, UploadUrlData } from '../types';

const placeholderBaseUrl =
  'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/CMS_TogetherXMusic';
const pladeholders = {
  videos: [
    'main.mp4',
    'dance-music-festival-3-2023-11-27-05-07-12-utc.mp4',
  ].map((url) => `${placeholderBaseUrl}/${url}`),
  photos: [
    '661d0415df9c18000ef68260.png',
    '663b184f507b11027a219680.png',
    '663df4804ad49e034b7e7524.png',
    '6626e29d6b6998000c4353f6.png',
    '66162bc398fd99000fbfed8c.png',
    '661849c28442f2000d3a7782.png',
    '662213f2aebf03000e7da8d6.png',
    '66155263aa7f4402ab80a465.png',
    '6679e195434a0a07c027cc76.png',
    '66704db7ecfe8b055d220bde.png',
    'IQOS-Lounge-MBArena-Madonna-0860287.jpg',
    'IQOS-Lounge-MBArena-Madonna-1370514.jpg',
    'attractive-jogger-listening-to-music-outside-2023-11-27-05-04-30-utc.jpg',
    'professional-musician-playing-and-composing-music-2023-11-27-04-54-20-utc.jpg',
    'Image_27.jpeg',
  ].map((url) => `${placeholderBaseUrl}/${url}`),
};

export const useTokyoUgcFeed = (): {
  feed: ComputedRef<TokyoFeed | undefined>;
  videoUploadUrl: ComputedRef<{ url: string; fields: Record<string, string> }>;
  imageUploadUrl: ComputedRef<{ url: string; fields: Record<string, string> }>;
  alreadyUploaded: ComputedRef<boolean>;
  errorMessage: ComputedRef<string | null>;
  mutateVideoUrl: () => void;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);

  const { headers } = useAuth();
  const {
    data: feed,
    error: feedError,
    mutate: mutateFeed,
  } = useSWRV<GetTokyoFeedDTO>(
    'get-tokyo-feed',
    () => getTokyoFeed({ limit: 100 })(headers.value),
    { revalidateOnFocus: false }
  );

  watch(feedError, handleError);

  const normalisedFeed = computed<TokyoFeed | undefined>(() => {
    const { video, photo, userinfo } = feed?.value || {};
    return (
      feed?.value && {
        videos: [
          ...(video?.results || []).map(({ url }) => url),
          ...pladeholders.videos,
        ],
        photos: [
          ...(photo?.results || []).map(({ url }) => url),
          ...pladeholders.photos,
        ],
        chancesToWin: userinfo?.count_approved || 0,
        todaysFeedIds: userinfo?.todays_feed_ids || [],
      }
    );
  });

  const normalisedImageUploadUrl = computed(() => {
    return imageUploadUrl.value && normaliseUploadUrl(imageUploadUrl.value);
  });

  const normalisedVideoUploadUrl = computed(() => {
    return videoUploadUrl.value && normaliseUploadUrl(videoUploadUrl.value);
  });

  const initError = ref(null);
  const initializeFeed = () => {
    getTokyoPhotoUrl(headers.value)
      .then(() => {
        setTimeout(() => {
          mutateFeed().then(() => {
            mutateImageUrl();
            mutateVideoUrl();
          });
        }, 3100);
      })
      .catch((e) => {
        initError.value = e?.response?.data?.user_error || e?.message;
      });
  };

  const {
    data: imageUploadUrl,
    error: imageError,
    mutate: mutateImageUrl,
  } = useSWRV(
    () => normalisedFeed.value && 'tokyo-image-url',
    () =>
      normalisedFeed.value.todaysFeedIds.length === 0
        ? initializeFeed()
        : getTokyoPhotoUrlForFeed(feed.value.userinfo.todays_feed_ids[0])(
            headers.value
          ),
    {
      revalidateOnFocus: false,
      refreshInterval: 300000,
      shouldRetryOnError: true,
      errorRetryCount: 1,
    }
  );

  const {
    data: videoUploadUrl,
    error: videoError,
    mutate: mutateVideoUrl,
  } = useSWRV(
    () => normalisedFeed.value && 'tokyo-video-url',
    () =>
      normalisedFeed.value.todaysFeedIds.length === 0
        ? () => null
        : getTokyoVideoUrlForFeed(feed.value.userinfo.todays_feed_ids[0])(
            headers.value
          ),
    {
      revalidateOnFocus: false,
      refreshInterval: 300000,
      shouldRetryOnError: true,
      errorRetryCount: 1,
    }
  );

  const normaliseUploadUrl = (item: UploadUrlDTO): UploadUrlData => {
    return item.upload_url;
  };

  const alreadyUploaded = computed(() => {
    return imageError.value?.response.status === 400 ||
      videoError.value?.response.status === 400
      ? true
      : false;
  });

  const errorMessage = computed(
    () =>
      imageError.value?.response?.data?.user_error ||
      videoError.value?.response?.data?.user_error ||
      initError.value ||
      null
  );

  return {
    feed: normalisedFeed,
    videoUploadUrl: normalisedVideoUploadUrl,
    imageUploadUrl: normalisedImageUploadUrl,
    alreadyUploaded,
    errorMessage,
    mutateVideoUrl,
  };
};
