import { generateResponsiveImageSet } from '@/utils/image';

export const responsiveImageSets = [
  generateResponsiveImageSet(
    'Porträt einer Person 1',
    '/together-x/reminder-cta-2/desktop/picture-01',
    '/together-x/reminder-cta-2/mobile/picture-01',
    'png'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 2',
    '/together-x/reminder-cta-2/desktop/picture-02',
    '/together-x/reminder-cta-2/mobile/picture-02',
    'png'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 3',
    '/together-x/reminder-cta-2/desktop/picture-03',
    '/together-x/reminder-cta-2/mobile/picture-03',
    'png'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 4',
    '/together-x/reminder-cta-2/desktop/picture-04',
    '/together-x/reminder-cta-2/mobile/picture-04',
    'png'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 5',
    '/together-x/reminder-cta-2/desktop/picture-05',
    '/together-x/reminder-cta-2/mobile/picture-05',
    'png'
  ),
];

export const responsiveImageSetsArtist = [
  generateResponsiveImageSet(
    'Porträt einer Person 1',
    '/together-x/reminder-cta-artist/desktop/picture-01',
    '/together-x/reminder-cta-artist/mobile/picture-01'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 2',
    '/together-x/reminder-cta-artist/desktop/picture-02',
    '/together-x/reminder-cta-artist/mobile/picture-02'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 3',
    '/together-x/application-step-4/simplified/michael_schulte/tipp-from-artist',
    '/together-x/application-step-4/simplified/michael_schulte/tipp-from-artist'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 4',
    '/together-x/reminder-cta-artist/desktop/picture-04',
    '/together-x/reminder-cta-artist/mobile/picture-04'
  ),
  generateResponsiveImageSet(
    'Porträt einer Person 5',
    '/together-x/application-step-4/simplified/bluemchen/tipp-from-artist',
    '/together-x/application-step-4/simplified/bluemchen/tipp-from-artist',
  ),
];
