import { Card } from '../types';

const twinCardsLightAnnouncement: Card[] = [
  {
    header: 'Bewirb dich.',
    description:
      'Wähle Dein Team. Mach ein Video. Und mit etwas Glück bist Du dabei.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card1/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card1/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Lass für dich Voten.',
    description: 'Stimme jeden Tag ab, wer Teil des Chors wird.',
    date: '',
    category: 'bühnenerfahrung',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card2/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card2/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card2/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card2/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Ab ins Studio.',
    description:
      'Als Teil des Chors geht es für Dich zum Trainings Camp nach Berlin.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card3/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card3/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card3/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card3/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card3/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card3/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Auf die Bühne.',
    description: 'Du und Dein Artist live auf der großen Festivalbühne.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card4/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card4/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card4/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card4/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card4/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card4/picture-mobile@3x.png'),
      },
    },
  },
];

const twinCardsLightApplication: Card[] = [
  {
    header: 'Bewirb dich.',
    description: 'Wähle Deinen Artist und bewirb Dich mit einem kurzen Video.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card1/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card1/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Lass für dich Voten.',
    description: 'Stimme jeden Tag ab, wer Teil des Chors wird.',
    date: '',
    category: 'bühnenerfahrung',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card2/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card2/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card2/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card2/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Ab ins Studio.',
    description:
      'Als Teil des Chors geht es für Dich zum Trainings Camp nach Berlin.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card3/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card3/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card3/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card3/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card3/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card3/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Auf die Bühne.',
    description: 'Du und Dein Artist live auf der großen Festivalbühne.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/light/card4/picture.png'),
        x2: require('@/assets/together-x/twinCards/light/card4/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card4/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/light/card4/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/light/card4/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/light/card4/picture-mobile@3x.png'),
      },
    },
  },
];

const twinCardsDarkAnnouncement: Card[] = [
  {
    header: 'Wähle Dein Team.',
    description:
      'Unterstütze Deinen Artist und profitiert gemeinsam von Punkten und mehr.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card1/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card1/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Vote jeden Tag.',
    description: 'Sammle Punkte und bestimme, wer es in den Chor schafft.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card2/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card2/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card2/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card2/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Entscheide mit.',
    description:
      'Gestalte die Reise mit und sichere Dir die Chance auf Festivaltickets.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card3/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card3/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card3/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card3/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card3/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card3/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Sei live dabei.',
    description:
      'Erhöhe Deine Chance auf Festivaltickets durch Voting und Mitgestalten.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card4/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card4/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card4/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card4/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card4/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card4/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Verpasse nichts.',
    description:
      'Probe, Backstage, Konzert – verfolge alles hautnah im IQOS CLUB.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card5/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card5/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card5/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card5/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card5/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card5/picture-mobile@3x.png'),
      },
    },
  },
];

const twinCardsDarkApplication: Card[] = [
  {
    header: 'Wähle Dein Team.',
    description:
      'Unterstütze Deinen Artist und profitiert gemeinsam von Punkten und mehr.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card1/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card1/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Vote jeden Tag.',
    description: 'Sammle Punkte und bestimme, wer es in den Chor schafft.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card2/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card2/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card2/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card2/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Entscheide mit.',
    description: 'Gestalte die Reise der IQOS Unexpected Voices mit.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card3/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card3/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card3/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card3/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card3/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card3/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Sei live dabei.',
    description:
      'Erhöhe Deine Chance auf Festivaltickets durch Voting und Mitgestalten.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card4/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card4/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card4/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card4/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card4/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card4/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Verpasse nichts.',
    description:
      'Probe, Backstage, Konzert – verfolge alles hautnah im IQOS CLUB.',
    date: '',
    category: '',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/twinCards/dark/card5/picture.png'),
        x2: require('@/assets/together-x/twinCards/dark/card5/picture@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card5/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/twinCards/dark/card5/picture-mobile.png'),
        x2: require('@/assets/together-x/twinCards/dark/card5/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/twinCards/dark/card5/picture-mobile@3x.png'),
      },
    },
  },
];

export {
  twinCardsLightAnnouncement,
  twinCardsLightApplication,
  twinCardsDarkAnnouncement,
  twinCardsDarkApplication,
};
