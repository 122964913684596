export const scrollIntoView = (elementQuery: string | HTMLElement): void => {
  // Done this way on top of native anchor linking
  // because native behavior on iOS devices is broken/disabled.
  const domElement = typeof elementQuery === 'string'
    ? document.querySelector<HTMLElement>( elementQuery)
    : elementQuery;
  const elementPosition: number = domElement.offsetTop;
  const headerOffset: number =
    document.querySelector<HTMLElement>('.nav-header')?.offsetHeight || 0;

  window.scrollTo({
    top: elementPosition - headerOffset,
    behavior: 'smooth', // Smoothing does not work on iOS, degrades gracefully
  });
};
