import { computed, watch, type ComputedRef } from 'vue';
import useSWRV from 'swrv';
import type { Applicant, GetApplicantListDTO } from '../types';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { useStore } from 'vuex';
import { useAuth } from '@/use/useAuth';

import { getAllApplicants } from '../api/api';

export const useApplicants = (): {
  applicantsList: ComputedRef<Applicant[]>;
  isPending: ComputedRef<boolean>;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);

  const { headers } = useAuth();
  const { data: applicantsList, error } = useSWRV<GetApplicantListDTO>(
    'get-together-x-applicants-list',
    () => getAllApplicants(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const normalisedApplicantsList = computed<Applicant[]>(() => {
    const mapper = (value, key) => camelCase(key);
    const result = (applicantsList.value?.results || []).map((value) =>
      mapKeys(value, mapper)
    );
    shuffleArray(result);
    return result as unknown as Applicant[];
  });

  const isPending = computed(() => !applicantsList.value);

  return {
    applicantsList: normalisedApplicantsList,
    isPending,
  };
};
