// import { computed, ComputedRef, watch } from 'vue';
// import useSWRV from 'swrv';
// import mapKeys from 'lodash/mapKeys';
// import camelCase from 'lodash/camelCase';
import { useStore } from 'vuex';

import { useAuth } from '@/use/useAuth';

import {
  // getSelections,
  postSelections,
} from '../api/api';

import type {
  // GetSelectionsDTO,
  PostSelectionsDTO,
  //SelectionsData,
} from '../types';

export const useSelections = (): {
  // selections: ComputedRef<SelectionsData>;
  saveSelection: (
    data: PostSelectionsDTO & { callback: () => void }
  ) => Promise<unknown>;
  // mutateContent: () => void;
  // isPending: ComputedRef<boolean>;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);
  // const refetch = () => mutate();

  const { headers } = useAuth();
  // const {
  //   data: votingData,
  //   mutate,
  //   error,
  // } = useSWRV<GetSelectionsDTO>(
  //   'get-together-x-selections',
  //   () => getSelections(headers.value),
  //   { revalidateOnFocus: false }
  // );

  // watch(error, handleError);

  // const normalisedSelectionsData = computed<SelectionsData>(() => {
  //   const mapper = (_, key) => camelCase(key);
  //   return {
  //     ...mapKeys(votingData.value || {}, mapper),
  //   } as unknown as SelectionsData;
  // });

  // const isPending = computed(() => !votingData.value);

  const saveSelection = (params) => {
    const { callback, ...postData } = params;
    return postSelections(postData)(headers.value)
      .then(callback)
      .catch(handleError);
  };
  return {
    // selections: normalisedSelectionsData,
    saveSelection,
    // mutateContent: refetch,
    // isPending,
  };
};
