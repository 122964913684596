import { computed, ComputedRef } from 'vue';
import useSWRV from 'swrv';

import { useAuth } from '@/use/useAuth';

import { getVideoUrl } from '../api/api';
import type { UploadUrlDTO } from '../types';

const normaliseUploadUrl = (
  item: UploadUrlDTO
): { url: string; fields: Record<string, string> } => {
  return item.upload_url;
};

export const useVideoUploadUrl = (): ComputedRef<{
  url: string;
  fields: Record<string, string>;
}> => {
  const { headers } = useAuth();
  const { data: videoUploadUrl } = useSWRV('video-url', () =>
    getVideoUrl(headers.value)
  );

  const normalisedUploadUrl = computed(() => {
    return normaliseUploadUrl(videoUploadUrl.value);
  });

  return normalisedUploadUrl;
};
