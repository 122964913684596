import { Card } from '../types';

const highlightCards: Card[] = [
  {
    header: 'Sammle Punkte.',
    date: '',
    category: '',
    description:
      'Sei aktiv und sichere Dir dabei zahlreiche Punkte, die Du z.B. für exklusive Prämien einlösen kannst.',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/highlightCards/card1/picture.png'),
        x2: require('@/assets/together-x/highlightCards/card1/picture@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card1/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/highlightCards/card1/picture-mobile.png'),
        x2: require('@/assets/together-x/highlightCards/card1/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card1/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Sei live dabei.',
    date: '',
    category: '',
    description: 'Gewinne Tickets für Festivals, exklusive Konzerte und mehr.',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/highlightCards/card2/picture.png'),
        x2: require('@/assets/together-x/highlightCards/card2/picture@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card2/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/highlightCards/card2/picture-mobile.png'),
        x2: require('@/assets/together-x/highlightCards/card2/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card2/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Entscheide mit.',
    date: '',
    category: '',
    description:
      'Kreiere einzigartige IQOS TogetherXMusic Momente mit der Community.',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/highlightCards/card3/picture.png'),
        x2: require('@/assets/together-x/highlightCards/card3/picture@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card3/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/highlightCards/card3/picture-mobile.png'),
        x2: require('@/assets/together-x/highlightCards/card3/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card3/picture-mobile@3x.png'),
      },
    },
  },
  {
    header: 'Mit Stars im Rampenlicht.',
    date: '',
    category: '',
    description: 'IQOS bringt Dich mit Stars auf die größten Festivalbühnen.',
    imageSet: {
      desktop: {
        x1: require('@/assets/together-x/highlightCards/card4/picture.png'),
        x2: require('@/assets/together-x/highlightCards/card4/picture@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card4/picture@3x.png'),
      },
      mobile: {
        x1: require('@/assets/together-x/highlightCards/card4/picture-mobile.png'),
        x2: require('@/assets/together-x/highlightCards/card4/picture-mobile@2x.png'),
        x3: require('@/assets/together-x/highlightCards/card4/picture-mobile@3x.png'),
      },
    },
  },
];

export default highlightCards;
