import { ResponsiveImageSet } from '@/types';
import { generateResponsiveImageSet } from '@/utils/image';

export const questionImages = [
  {
    responsiveImageSet: generateResponsiveImageSet(
      'IQOS Nutzerin genießen Musik in einem Club',
      '/together-x/application-step-4/desktop/picture-1',
      '/together-x/application-step-4/mobile/picture-1'
    ),
    question: 'Über Dich: Wie heißt du und wer bist du?',
  },
  {
    responsiveImageSet: generateResponsiveImageSet(
      'Kool Savas, Blümchen und Michael Schulte stehen auf der Bühne',
      '/together-x/application-step-4/desktop/picture-2',
      '/together-x/application-step-4/mobile/picture-2'
    ),
    question:
      'Dein Chor: Warum möchtest Du mit Deinem Artist auf der Bühne stehen?',
  },
  {
    responsiveImageSet: generateResponsiveImageSet(
      'Glückliche IQOS Nutzerin',
      '/together-x/application-step-4/desktop/picture-3',
      '/together-x/application-step-4/mobile/picture-3'
    ),
    question: 'Deine Story: welche Entscheidung hat Dein Leben beeinflusst?',
  },
  {
    responsiveImageSet: generateResponsiveImageSet(
      'IQOS Nutzerin genießen Musik auf einem Festival',
      '/together-x/application-step-4/desktop/picture-4',
      '/together-x/application-step-4/mobile/picture-4'
    ),
    question: 'Deine Musik: welche Rolle hat Musik in deinem Leben?',
  },
];

export const getArtistQuestionImage = (
  normalisedArtistName: string
): ResponsiveImageSet => {
  const images = {
    kool_savas: generateResponsiveImageSet(
      'Kool Savas Tip',
      '/together-x/application-step-4/desktop/kool_savas/tipp-from-artist',
      '/together-x/application-step-4/desktop/kool_savas/tipp-from-artist',
      'png'
    ),
    bluemchen: generateResponsiveImageSet(
      'Blümchen Tip',
      '/together-x/application-step-4/desktop/bluemchen/tipp-from-artist',
      '/together-x/application-step-4/desktop/bluemchen/tipp-from-artist',
      'png'
    ),
    michael_schulte: generateResponsiveImageSet(
      'Michael Schulte Tip',
      '/together-x/application-step-4/desktop/michael_schulte/tipp-from-artist',
      '/together-x/application-step-4/desktop/michael_schulte/tipp-from-artist',
      'png'
    ),
  };

  return images[normalisedArtistName || 'kool_savas'];
};

export const getArtistQuestionImageNoText = (
  normalisedArtistName: string
): ResponsiveImageSet => {
  const images = {
    kool_savas: generateResponsiveImageSet(
      'Kool Savas Tip',
      '/together-x/application-step-4/simplified/kool_savas/tipp-from-artist',
      '/together-x/application-step-4/simplified/kool_savas/tipp-from-artist'
    ),
    bluemchen: generateResponsiveImageSet(
      'Blümchen Tip',
      '/together-x/application-step-4/simplified/bluemchen/tipp-from-artist',
      '/together-x/application-step-4/simplified/bluemchen/tipp-from-artist'
    ),
    michael_schulte: generateResponsiveImageSet(
      'Michael Schulte Tip',
      '/together-x/application-step-4/simplified/michael_schulte/tipp-from-artist',
      '/together-x/application-step-4/simplified/michael_schulte/tipp-from-artist'
    ),
  };

  return images[normalisedArtistName || 'kool_savas'];
};

export const suggestions = [
  {
    icon: 'account',
    title: 'Sprich direkt in die Kamera.',
    description:
      'Positioniere Dich direkt vor der Kamera. Optimalerweise sieht man Dich bis zum Brustkorb.',
  },
  {
    icon: 'rewards',
    title: 'Mach das Video hochkant.',
    description:
      'Nimm das Video hochkant auf. So wie Du Dein Handy normal in der Hand hältst.',
  },
  {
    icon: 'exceptional-services',
    title: 'Achte auf eine ruhige Umgebung.',
    description:
      'Setze Dich an einen ruhigen Ort. Idealerweise fällt Tageslicht vom Fenster auf Dein Gesicht.',
  },
];

export const artistAdvices = {
  'artist-1': generateResponsiveImageSet(
    'Blümchen im Aufnahmestudio',
    '/together-x/application-step-4/desktop/tipp-from-bluemchen',
    '/together-x/application-step-4/mobile/tipp-from-bluemchen'
  ),
  'artist-2': generateResponsiveImageSet(
    'Kool Savas im Aufnahmestudio',
    '/together-x/application-step-4/desktop/tipp-from-kool-savas',
    '/together-x/application-step-4/mobile/tipp-from-kool-savas'
  ),

  'artist-3': generateResponsiveImageSet(
    'Michael Schulte im Aufnahmestudio',
    '/together-x/application-step-4/desktop/tipp-from-michael-schulte',
    '/together-x/application-step-4/mobile/tipp-from-michael-schulte'
  ),
};

export const ARTIST_NAMES = {
  kool_savas: 'Kool Savas',
  bluemchen: 'Blümchen',
  michael_schulte: 'Michael Schulte',
};
