import { computed, ComputedRef, watch } from 'vue';
import useSWRV from 'swrv';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { useStore } from 'vuex';

import { useAuth } from '@/use/useAuth';

import { getApplication, postApplication } from '../api/api';
import type {
  Application,
  GetApplicationDTO,
  PostApplicationDTO,
} from '../types';

export const useApplication = (): {
  application: ComputedRef<Application>;
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  save: (data: PostApplicationDTO) => Promise<any>;
  isPending: ComputedRef<boolean>;
} => {
  const store = useStore();
  const handleError = (err) => store.commit('PUSH_ERROR', err);

  const { headers } = useAuth();
  const {
    data: application,
    mutate,
    error,
  } = useSWRV<GetApplicationDTO | null>(
    'get-together-x-application-process',
    () => getApplication(headers.value),
    { revalidateOnFocus: false }
  );

  watch(error, handleError);

  const normalisedApplication = computed<Application>(() => {
    const mapper = (value, key) => camelCase(key);
    return mapKeys(application.value?.application || {}, mapper);
  });

  const isPending = computed(() => !application.value);

  const saveApplication = (postData: PostApplicationDTO) =>
    postApplication(postData)(headers.value)
      .then(() => mutate()) // Revalidate after saving
      .catch(handleError);

  return {
    application: normalisedApplication,
    save: saveApplication,
    isPending,
  };
};
